import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import { SwiperSlide } from 'swiper/react'
import Icon from '../../atoms/icon'

const shouldForwardProp = (prop) => !['action', 'aboveTheFold'].includes(prop)

const Container = styled(Box, { shouldForwardProp })`
	position: relative;
	--swiper-navigation-size: 48px;
	height: ${({ aboveTheFold }) => (aboveTheFold ? '100%' : undefined)};
`

const NavButton = styled(Icon, { shouldForwardProp })`
	${({ action }) => (action === 'prev' ? 'left: 0;' : 'right: 0;')}
	background-color: var(--swiper-button-bg-color, #D9D9D9);
	border-radius: var(--swiper-button-border-radius, 50%);
	backdrop-filter: blur(50px);
	font-size: ${({ theme }) => theme.typography.pxToRem(48)};
	transition: background-color 0.3s;

	&:hover {
		background-color: #fff;
	}

	&.swiper-button-disabled {
		pointer-events: auto;
		display: none;
	}

	&.swiper-button-prev {
		left: var(--spacing-2);
	}

	&.swiper-button-next {
		right: var(--spacing-2);
	}

	&.swiper-button-lock {
		display: none;
	}

	&.swiper-button-lock {
		display: none;
	}

	${({ theme }) => theme.breakpoints.down('md')} {
		display: none;
	}
`

const PaginationStyled = styled('div')`
	position: relative;
	margin-top: var(--spacing-4);
	&.swiper-pagination-horizontal {
		bottom: 0px;
		width: auto;
		left: 50%;
		transform: translateX(-50%);
		${({ theme }) => theme.breakpoints.up('md')} {
			width: 100%;
			left: 0;
			transform: none;
			bottom: 20px;
		}
	}

	&.swiper-pagination-lock {
		display: none;
	}

	&.swiper-pagination-bullets {
		line-height: 0;
	}

	.swiper-pagination-bullet {
		--swiper-pagination-bullet-horizontal-gap: var(--spacing-1);
		background-color: transparent;
		height: auto;
		margin: 0 var(--swiper-pagination-bullet-horizontal-gap);
		opacity: 1;
		padding: 0;
		width: auto;
		&:before {
			background-color: var(--swiper-pagination-bullet-color, transparent);
			border: 1px solid var(--color-primary);
			border-radius: 50%;
			content: '';
			display: block;
			height: ${({ theme }) => theme.spacing('6px')};
			width: ${({ theme }) => theme.spacing('6px')};
		}
		&-active {
			cursor: default;
			&:before {
				background-color: var(--swiper-pagination-bullet-active-color, var(--color-primary));
			}
		}
	}

	&.swiper-pagination-lock {
		display: none;
	}
`

const SwiperSlideStyled = styled(SwiperSlide, { shouldForwardProp })`
	display: ${({ aboveTheFold }) => (aboveTheFold ? 'flex' : undefined)};
`

const ScrollbarStyled = styled('div')`
	/* Edit swiper variables in css-baseline.js */
	&.swiper-scrollbar {
		background: ${({ theme }) => `var(--swiper-scrollbar-bg-color, ${theme.palette.grey.lighter})`};
		width: calc(100% - (var(--swiper-scrollbar-sides-offset, 1%) * 2));
		left: var(--swiper-scrollbar-sides-offset, 1%);
		right: var(--swiper-scrollbar-sides-offset, 1%);
		bottom: var(--swiper-scrollbar-bottom, 0px);
		z-index: 1;
		.swiper-scrollbar-drag {
			background: ${({ theme }) => `var(--swiper-scrollbar-drag-bg-color, ${theme.palette.grey.main})`};
			border-radius: ${({ theme }) => `var(--swiper-scrollbar-border-radius, ${theme.shape.borderRadius}px)`};
			cursor: grab;
		}
	}
`

export { Container, NavButton, PaginationStyled, SwiperSlideStyled, ScrollbarStyled }
